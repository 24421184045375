import React, {useEffect} from 'react';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {tooglingMenu} from "./state/shared";

const MobileMenu = () => {
    const dispatch = useDispatch();
    const showMenu = useSelector((state) => state.shared.showMenu);
    const location = useLocation();
    useEffect(() => {
        dispatch(tooglingMenu(false))
    }, [location]);
    const toogleMenu = () => {
        dispatch(tooglingMenu(!showMenu));
    };
    return (<div id="mobile-menu">
        <FontAwesomeIcon icon={faBars} className="menu-bars" onClick={toogleMenu}/>
    </div>);
}

export {MobileMenu};
