import React from "react";

class Portraits extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="portraits">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <div id="painting-dogana">
                            <img src='/petits-formats-2014/portrait-1.webp' className="painting-image portrait-item">
                            </img>
                            <img src='/petits-formats-2014/portrait-2.webp' className="painting-image portrait-item">
                            </img>
                        </div>
                        <div className="painting-title">
                            Portraits
                            <br/>
                            Octobre 2014
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Portraits</h3>
                    <p className="painting-item-text">
                        Trois portraits de femmes, posées sur une boule dorée, Pointe des douanes à Venise...
                        <br/><br/>
                        Du haut de cette petite tour de l'esprit, figure de proue à l'embouchure du Grand Canal, elles
                        temoignent de la nature vénitienne...
                        <br/><br/>
                        Etandarts tournoyant comme des girouettes face à la mer et aux vents, elles informent le
                        promeneur de ce temps qui passe..."
                    </p>
                </div>
            </div>
        );
    }

}

export {Portraits};
