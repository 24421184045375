import React from "react";

class Rappaccini extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}}>
                <div className="painting-item-left" id="rappacini">
                    <div className="painting-container">
                        <img src='/grostesque-2015/rappaccini.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La fille de Rappaccini
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" id="traviata">
                    <div className="painting-container">
                        <img src='/grostesque-2015/traviata.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La Traviata
                            <br/>
                            Avril 2015
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Rappaccini};
