import React from "react";

class Arbre extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="arbre">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/arbre.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Arbre généalogique
                            <br/>
                            Juin 2014
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Arbre généalogique</h3>
                    <p className="painting-item-text">
                        Extrait de notes...
                        <br/><br/>
                        " Je me suis aperçu derniérement, en lisant "Nigth Train" de Nick Tosches, que dans un récit, la
                        soif du détail, cette énumération du plus important ou plus petit, cette idolâtrie de l'heure et
                        du nom et du lieu était ce que je respectais et qui me captivais le plus... La joie métaphysique
                        de ce que Gilbert Lely appelle "la dignité spécifique des faits, même à l'affleurement humain le
                        plus obscur."
                        <br/><br/>
                        De la somme d'informations invraisemblables que Nick Tosches collecte de la vie du boxeur Liston
                        pour écrire son livre, rien ne sera oublié... Tout sera relaté, jusqu'à la plus infime parcelle
                        de vie. Et cette gymnastique de l'esprit qui le fait passez d'un fait à l'autre, à chaque fois,
                        et que je ressens comme si l'on pressait une éponge ( ce "fétichisme du réel" pour reprendre
                        encore une expression de Lely dans sa préface de la biographie du Marquis de Sade) donne à son
                        récit cette force envoutante, ce lyrisme noir à l'éclat de perle blanche qui me parle, qui remue
                        sur mes lêvres et que j'apprécie par dessus tout.
                        <br/><br/>
                        Je me suis donc demandé si dans la peinture cette forme de discours existait... "
                    </p>
                </div>
            </div>
        );
    }

}

export {Arbre};
