import React from "react";

class QuatorzeIntro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/fourteen-2013/fiance.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La fiancé du pirate
                            <br/>
                            2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Quatorze oeuvres 2013</h3>
                    <p className="painting-item-text">
                        "Le travail de Juillet à Décembre.
                        <br/><br/>
                        Quatorze tableaux, différents des précédents par la forme du regard et des yeux.
                        <br/><br/>
                        Compositions marquées par le goût de vivre et les aléas de la vie..."
                        <br/><br/>
                        <i>Sixte</i>
                        <br/><i>Les Issambres</i>
                        <br/><i>23 décembre 2013</i>
                    </p>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/fourteen-2013/quatorze.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Dans l'atelier
                            <br/>
                            Les Issambres
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {QuatorzeIntro};
