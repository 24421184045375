import React from "react";

class Piercing extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="piercing">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/piercing.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Piercing sur la langue
                            <br/>
                            Mars 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Piercing sur la langue</h3>
                    <p className="painting-item-text">
                        "une voix de soprano, une musicienne, voilà ce que j'écoute à la radio en traçant son visage...
                        <br/>
                        Je lui ai posé un percing dans la bouche pour me rappeler le son de la musique .
                        <br/>
                        Puis par la couleur, je l'ai fardé comme une cantatrice, une diva..."
                    </p>
                </div>
            </div>
        );
    }

}

export {Piercing};
