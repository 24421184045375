import React from 'react';
import {createRoot} from 'react-dom/client';
import "./index.scss"
import {NavBar} from './navbar.js';
import {IntlProvider} from 'react-intl';
import French from './i18n/fr.json';
import English from './i18n/en.json';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Portrait} from "./pages/portrait/portrait";
import {Home} from "./pages/home/home";
import {GrandsFormats2015} from "./pages/grands-formats-2015/grands-formats-2015";
import {Grotesque2015} from "./pages/grotesque-2015/grotesque-2015";
import {GrandsFormats2014} from "./pages/grands-formats-2014/grands-formats-2014";
import {PetitsFormats2014} from "./pages/petits-formats-2014/petits-formats-2014";
import {Quatorze2013} from "./pages/quatorze-2013/quatorze-2013";
import {Alphabet2013} from "./pages/alphabet-2013/alphabet-2013";
import {Footer} from "./footer";

import {Provider} from 'react-redux'
import store from './state/store'
import {WebMenu} from "./web-menu";
import {MenuDropdown} from "./menu-dropdown";


const locale = navigator.language;

let lang;
if (locale === "en") {
    lang = English;
} else {
    lang = French;
}

export const App = () => {
    return (
        <Provider store={store}>
            <IntlProvider locale={locale.substr(0, 2)} messages={French}>
                <Router>
                    <NavBar></NavBar>
                    <WebMenu></WebMenu>
                    <MenuDropdown></MenuDropdown>
                    <div className="container">
                        <div className="pages-container">
                            <Routes>
                                <Route exact path='/' element={<Home/>}></Route>
                                <Route exact path='/home' element={<Home/>}></Route>
                                <Route exact path='/portrait' element={<Portrait/>}></Route>
                                <Route exact path='/grands-formats-2015' element={<GrandsFormats2015/>}></Route>
                                <Route exact path='/grotesque-2015' element={<Grotesque2015/>}></Route>
                                <Route exact path='/grands-formats-2014' element={<GrandsFormats2014/>}></Route>
                                <Route exact path='/petits-formats-2014' element={<PetitsFormats2014/>}></Route>
                                <Route exact path='/quatorze-2013' element={<Quatorze2013/>}></Route>
                                <Route exact path='/alphabet-2013' element={<Alphabet2013/>}></Route>
                            </Routes>
                        </div>
                    </div>
                </Router>
                <Footer></Footer>
            </IntlProvider>
        </Provider>
    );

}

const root = createRoot(document.getElementById('root'));
root.render(<App tab="home"/>);
