import React from "react";

class Others extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="painting-item" style={{alignItems: "flex-end"}}>
                    <div className="painting-item-left"  id="mitrale">
                        <div className="painting-container">
                            <img src='/fourteen-2013/mitrale.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Insufisance mitrale
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="marecage">
                        <div className="painting-container">
                            <img src='/fourteen-2013/marecage.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Marécage
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}} id="astrolabe">
                    <div className="painting-item-left">
                        <div className="painting-container">
                            <img src='/fourteen-2013/astrolabe.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Astrolabe
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="chevalier">
                        <div className="painting-container">
                            <img src='/fourteen-2013/chevalier.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Chevalier errant
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}}>
                    <div className="painting-item-left" id="octobre">
                        <div className="painting-container">
                            <img src='/fourteen-2013/octobre.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Les vergers d'octobre
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="novembre">
                        <div className="painting-container">
                            <img src='/fourteen-2013/novembre.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Les vergers de novembre
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item">
                    <div className="painting-item-left" style={{alignItems: "center"}} id="decembre">
                        <div className="painting-container">
                            <img src='/fourteen-2013/decembre.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Les vergers de décembre
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}}>
                    <div className="painting-item-left" id="faranelli">
                        <div className="painting-container">
                            <img src='/fourteen-2013/faranelli.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Faranelli
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="leo">
                        <div className="painting-container">
                            <img src='/fourteen-2013/leo.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Leo, by night on the beach
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}}>
                    <div className="painting-item-left" id="adm">
                        <div className="painting-container">
                            <img src='/fourteen-2013/adm.webp' className="painting-image"></img>
                            <div className="painting-title">
                                A. de M.
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="fee">
                        <div className="painting-container">
                            <img src='/fourteen-2013/fee.webp' className="painting-image"></img>
                            <div className="painting-title">
                                La fée de la forêt
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}}>
                    <div className="painting-item-left" id="sahara">
                        <div className="painting-container">
                            <img src='/fourteen-2013/sahara.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Sahara
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="marais">
                        <div className="painting-container">
                            <img src='/fourteen-2013/marais.webp' className="painting-image"></img>
                            <div className="painting-title">
                                L'esprit des marais
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Others};
