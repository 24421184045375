import React from "react";

class Sphinx extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="painting-item" id="sphinx">
            <div className="painting-item-left" style={{alignItems: "flex-end"}}>
                <div className="painting-container">
                    <img src='/alphabet-2013/sphinx.webp' className="painting-image"></img>
                    <div className="painting-title">
                        Sphinx-Nonza
                        <br/>
                        Février 2013
                    </div>
                </div>
            </div>
            <div className="painting-item-right">
                <h3 className="painting-text-subtitle">Sphinx-Nonza</h3>
                <p className="painting-item-text">
                    Toujours Leonor Fini. Le monastère de Nonza se trouve près du Cap Corse. Un lieu sauvage, venté,
                    unique. Elle y passait l'été. De splendides photos d'elle ont été prises là-bas.
                    <br/>
                    De chez moi, aux Issambres, à l'aube, les mois d'hiver, on distingue le Cap Corse.
                </p>
                <div className="painting-container">
                    <img src='/alphabet-2013/corse.webp' className="painting-image"></img>
                    <div className="painting-title">
                        Le cap Corse à l'horizon, au lever du soleil.
                        <br/>
                        Vu des Issambres
                    </div>
                </div>
            </div>
        </div>);
    }

}

export {Sphinx};
