import React from "react";

class Katmandou extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}}>
                <div className="painting-item-left" id="katmandou">
                    <div className="painting-container">
                        <img src='/grostesque-2015/katmandou.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Katmandou
                            <br/>
                            Mai 2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" id="scribe">
                    <div className="painting-container">
                        <img src='/grostesque-2015/scribe.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Scribe Népalais
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
            </div>);
    }

}

export {Katmandou};
