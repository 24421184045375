import React from "react";

class Jardins extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="jardins">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/grands-formats-2015/jardins.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Le jardin d'acclimatation
                            <br/>
                            (148 X 122) 2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Le jardin d'acclimatation</h3>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/grands-formats-2015/sixte-jardin.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Les Issambres
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Jardins};
