import React from "react";

class Galiano extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="painting-item" id="galiano">
                <div className="painting-item-left" style={{justifyContent: "flex-end"}}>
                    <div className="painting-container">
                        <img src='/alphabet-2013/galiano.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Portrait de John Galiano
                            <br/>
                            Janvier 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Portrait de John Galiano</h3>
                    <p className="painting-item-text">
                        Je ne sais pas à quel moment cette composition est devenue le portrait de John Galiano, mais
                        dès le début il m'est venu à l'idée que j'étais en train de traçer sur le bois les traits du
                        visage
                        de ce célébre couturier dont j'avais vu la photo sur le journal du matin à l'occasion de la
                        fashion week new yorkaise de janvier 2013.
                    </p>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/alphabet-2013/atelier-galiano.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Dans l'atelier
                            <br/>
                            Les Issambres
                        </div>
                    </div>
                </div>
            </div>);
    }

}

export {Galiano};
