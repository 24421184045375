import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export const MenuDropdown = () => {
    const showMenu = useSelector((state) => state.shared.showMenu);
    const location = useLocation();
    let pathname = location.pathname;
    let path = pathname.split("#")[0].replaceAll("/", "");
    return (<div className="menu-dropdown-container">
        <div className="menu-dropdown" style={{
            display: showMenu ? "flex" : "none"
        }}>
            <Link className="mobile-menu-item" to="/home">
                <FormattedMessage id="menu.home"/>
            </Link>
            <div className="menu-horizontal-bar"></div>
            {/*<Link className="mobile-menu-item" to="/portrait">*/}
            {/*    <FormattedMessage id="menu.portrait"/>*/}
            {/*</Link>*/}
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/grands-formats-2015">
                Grands formats 2015
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/grotesque-2015">
                Grotesque 2015
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/grands-formats-2014">
                Grands formats 2014
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/petits-formats-2014">
                Petits formats 2014
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/quatorze-2013">
                Quatorze 2013
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to="/alphabet-2013">
                Alphabet 2013
            </Link>
            <div className="menu-horizontal-bar"></div>
            <Link className="mobile-menu-item" to={"/" + path + "#contact"}>
                Contact
            </Link>
        </div>
    </div>)
};