import React from "react";

class LesDeuxAmies extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="amies">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/amies.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Les deux amies
                            <br/>
                            (140 x 100)
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Les deux amies</h3>
                    <p className="painting-item-text">
                        "On dirait que l'ecume est vraie, que la mer est vraie, vrai la conque, vrai le souffle des
                        vents; on pourrait voir briller les yeux de la déesse, et le ciel et les éléments rirent autour
                        d'elle.; les Heures en robe blanche fouler le sable; le zephir boucler leurs longs cheveux
                        épars. leur visage n'est ni le même ni pas le même comme il convient à des soeurs."
                    </p>
                </div>
            </div>
        );
    }

}

export {LesDeuxAmies};
