import React from "react";

class GrandsFormatsIntro2014 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/grands-formats-intro.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Inachevé
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Grands formats 2014</h3>
                    <p className="painting-item-text">
                        Dernier grand format, Novembre 2014, "le regard des autres"...
                        <br/><br/>
                        Une" base de moisissure aux tendres saules pleureurs amis de la lune et de l'eau"
                        <br/>
                        (O. de Lubicz-Milocz)...
                        <br/><br/>
                        J'ai composé comme s'il s'agissait d'une fresque dans un hall de vieux cinema.
                    </p>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/grands-formats-2014/atelier.webp' className="painting-image"></img>
                        <div className="painting-title">
                            L'atelier
                            <br/>
                            Les issambres
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {GrandsFormatsIntro2014};
