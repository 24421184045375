import React from "react";

class PortraitIntro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="painting-item">
            <div className="painting-item-left">
                <div className="painting-container">
                    <img src='/grands-formats-2015/sixte-jardin.webp' className="painting-image"></img>
                    <div className="painting-title">
                        Les Issambres
                        <br/>
                        2015
                    </div>
                </div>
            </div>
            <div className="painting-item-right">
                <h3 className="painting-text-subtitle">Portrait de Sixte de Mijolla</h3>
                <p className="painting-item-text">
                    Naissance le 8 Mai 1952 à Vesoul en France.
                    <br/><br/>
                    Très tôt inité à la peinture par une tante, élève du peintre cubiste Albert Gleizes, il ira passer
                    ses vacances à Moly Sabata, une communauté d'artistes créée par Gleizes à Serrières, sur les berges
                    du Rhône, et dont il garde des souvenirs précis.
                    <br/><br/>
                    Chez ses parents, il affectionne les œuvres de son bisaïeul le peintre impressionniste Joseph
                    Trévoux qui ornent les murs du salon. En quelques jours, un vieil oncle Gabriel Brassard, peintre,
                    graveur, inspecteur des Monuments Historiques, lui fait apprécier et comprendre les règles de
                    l'impressionnisme en peinture.
                    <br/><br/>
                    A 14 ans, lors d'un voyage en Italie accompagné par un oncle, il mémorise pour toujours les grandes
                    oeuvres de la Rennaissance Italienne.
                    <br/><br/>
                    Dés 18 ans, il apprécie et collectionne les affiches de theatre du graphiste polonais Starowiesky
                    que lui font connaitre ses amis des Beaux Arts de Quimper.
                    <br/><br/>
                    Dés 1973, parallèlement à ses études chez les Pères Maristes de St Chamond, il suit les conférences
                    d'histoire de l'art de Bernard Ceysson, alors directeur du Musée d'Art et d'Industrie de St Etienne.
                    <br/><br/>
                    De 1974 à 1983, il se consacre à la poésie et publie de nombreux recueils de poêmes sur Venise où il
                    se rend régulièrement.
                    <br/><br/>
                    Dés 1973, son ami, le peintre Frank Galendo rencontré chez sa tante, lui fait connaitre l'œuvre de
                    Leonor Fini. Il lui adresse ses poêmes. Une longue correspondance s'en suivra. Par l'intermédiaire
                    de Leonor Fini, il entrera en contact avec le poète-écrivain-biographe de Sade, Glbert Lely, qu'il
                    admire pour sa pensée et la qualité de son style.
                    <br/><br/>
                    Avec Frank, il cotoie plusieurs fois le charismatique sculpteur Réne Durrbach (les vitraux de la
                    basilique de Charleville Meyziére) et restera profondément marqué par ses rencontres.
                    <br/><br/>
                    Il se marie en 1979. suivront quatre enfants, trois garçons et une fille.
                    <br/><br/>
                    Commence alors une longue carrière professionnelle dans le montage de salon et l'évènementiel.
                    L'activité artistique est alors délaissée.
                    <br/><br/>
                    Il partage aujoud'hui sa vie entre les Issambres (sur la Côte d'Azur) où il réside depuis 25 ans
                    avec Marie-Agnés sa femme et Malpas (une demeure familiale située dans la région stéphanoise à une
                    cinquantaine de kilomètres de Lyon ).
                    <br/><br/>
                    Ses premiers dessins datent de 2006, des illustrations des correspondances de Voltaire. Il réalise à
                    cette occasion son premier alphabet, pratique serielle qu'il garde encore aujourd'hui, significative
                    de sa démarche sur le langage et de ses préoccupations.
                    <br/><br/>
                    Il se consacre à la peinture à partir de 2009.
                    <br/><br/>
                    La video de 2011 qui fait suite, réalisée par son cousin Bruno Courtot de Cissey, parle de ce
                    cheminement.
                    <br/><br/>
                    Fin 2012, il décide de consacrer l'année 2013 à la réalisation de portraits imaginaires,
                    privilégiant la même technique, dans un style qui est désormais le sien.
                    <br/><br/>
                    Ce site est le documentaire visuel de cette nouvelle aventure.
                </p>
            </div>
        </div>)
    }

}

export {PortraitIntro};
