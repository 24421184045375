import React from "react";

class Megere extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="megere">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/megere.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La mégère apprivoisée
                            <br/>
                            Février 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">La mégère apprivoisée</h3>
                    <p className="painting-item-text">
                        "La mégère apprivoisée"...
                        <br/>
                        Je me souviens avoir vu à la télé Liz Taylor poursuivie par Richard Burton sur le toit d'une
                        grange. Je ne devais pas avoir dix ans.
                    </p>
                </div>
            </div>
        );
    }

}

export {Megere};
