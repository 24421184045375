import React, {useEffect} from "react";
import {MobileMenu} from "./mobile-menu";
import {Link, useLocation} from "react-router-dom";

export const NavBar = () => {
    const location = useLocation();
    setTimeout(() => {
        if (!!location.hash && location.hash.replaceAll("#", "").length > 0) {
            let target = document.getElementById(location.hash.replaceAll("#", ""))
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });
        }
    }, 500);
    return (
        <div id="navbar">
            <MobileMenu></MobileMenu>
            <div className="container">
                <Link id="logo" to="/home">
                    <div id="sixte">Sixte</div>
                    <div id="logo-right">
                        <div id="de-mijolla">
                            <div>de Mijolla</div>
                            <div>Peintures</div>
                        </div>
                        <div id="logo-bar"></div>
                        <div id="issambres">
                            Les issambres
                            2010 2022
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

