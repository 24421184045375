import React from "react";

class GrotesqueIntro2015 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grostesque-2015/grotesque.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Grotesque
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" style={{justifyContent: "flex-start"}}>
                    <h3 className="painting-text-subtitle">Grotesque 2015</h3>
                    <p className="painting-item-text">
                        <br/>
                        L'un de mes derniers tableaux de 2014 fut une tête.
                        <br/><br/>
                        Je lui donnais comme titre "Grotesque".
                        <br/><br/>
                        En mars 2015, je repris ce sujet pour en faire une série.
                    </p>
                </div>
            </div>
        );
    }

}

export {GrotesqueIntro2015};
