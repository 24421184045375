import React from "react";

class EauProfonde extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}}>
                <div className="painting-item-left" id="eau-profonde">
                    <div className="painting-container">
                        <img src='/grostesque-2015/eau-profonde.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Eau profonde
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <div className="painting-container" id="salamandre">
                        <img src='/grostesque-2015/salamandre.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Jeune fille à la Salamandre
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {EauProfonde};
