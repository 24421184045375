import React from 'react'
import {useSelector} from "react-redux";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <div id="footer">
            <div id="footer-container" className="container">
                <div>
                    <div>
                        <h4 className="footer-title" id="contact">Contact</h4>
                        <div>
                            <div className="footer-text">Sixte de Mijolla</div>
                            <div className="footer-paragraph">LES ISSAMBRES - FRANCE</div>
                            <div className="footer-text">Email: <a href="mailto:sixtedemijolla@gmail.com" id="mail-to">
                                sixtedemijolla@gmail.com
                            </a></div>
                            <div className="footer-text">Tél. : +33 (0)6 49 47 95 59</div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <div className="copyrights">
                        © {year} Sixte de Mijolla - Peintures
                    </div>
                    <div className="copyrights">
                        Réalisation site Web: <a href="https://maxime.demijolla.fr" className="custom-link">
                        mimX@
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    Footer
};
