import {Link} from "react-router-dom";

export const Article = (props) => {
    let clazz = "article";
    if (!!props.clazz) {
        clazz += " " + props.clazz;
    }
    return (<Link className={clazz} to={props.linkTo}>
        <div className="article-picture" style={{backgroundImage: props.backgroundImage}}>
        </div>
        <div className="article-text">
            {props.articleTitle}
        </div>
    </Link>)
}
