import React from "react";

class Others extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="painting-item" style={{alignItems: "flex-end"}} id="chat">
                    <div className="painting-item-left">
                        <div className="painting-container">
                            <img src='/alphabet-2013/chat.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Le chat botté
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right" id="grenouille">
                        <div className="painting-container">
                            <img src='/alphabet-2013/grenouille.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Grenouille
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
                <div className="painting-item" style={{alignItems: "flex-end"}} id="lion">
                    <div className="painting-item-left">
                        <div className="painting-container">
                            <img src='/alphabet-2013/lion.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Le lion de batou
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                    <div className="painting-item-right">
                        <div className="painting-container">
                            <img src='/alphabet-2013/paille.webp' className="painting-image"></img>
                            <div className="painting-title">
                                Courte paille
                                <br/>
                                2013
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Others};
