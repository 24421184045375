import React from "react";

class Adele extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="adele">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/adele.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Adèle de Bellegarde
                            <br/>
                            Juin 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Adèle de Bellegarde</h3>
                    <p className="painting-item-text">
                        La modéle du peintre David. (l'égerie de l'immense composition de "l'enlévement des Sabines").
                        La Marianne du timbre
                    </p>
                </div>
            </div>
        );
    }

}

export {Adele};
