import React from "react";

class VisiteursSoir extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="visiteurs">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/visiteurs.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Visiteurs du soir
                            <br/>
                            Février 2014 (160 x 122)
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Visiteurs du soir</h3>
                    <p className="painting-item-text">
                        Y a-t-il une heure précise pour peindre?
                        <br/><br/>
                        Je n'en sais rien.
                        <br/><br/>
                        Je me suis simplement aperçu que ce que je réalise la nuit et qui me semble réussi, s'avére
                        souvent consternant à la lumiére du jour... Ou le contraire.
                        <br/><br/>
                        Donc, je m'intérroge à ce sujet.
                        <br/><br/>
                        Dans "les Visiteurs du soir", les couleurs du soir associées à la nuit ont finalement résisté à
                        la clarté du jour... D'où le titre du tableau.
                        J'ai vu derniérement un documentaire sur Marguerite Duras.
                        <br/><br/>
                        C'est l'un des rares auteurs du XXeme siécle qui associe dans ses propos générosité et création
                        littérraire.
                        <br/><br/>
                        Accoudée à une table de salon, elle parle de sa vie depuis une dizaine d'années, désormais
                        seule, mais qui la rend plus généreuse et lui permet d'écrire beaucoup plus.
                        <br/><br/>
                        A l'entendre ainsi parler
                        <br/><br/>
                        on comprend qu'elle insiste sur l'importance du don de soi dans la création littérraire..
                        <br/><br/>
                        Et finalement on s'aperçoit qu'elle raméne aux bases d'une pensée profondément humaniste où
                        écrire un livre est toujours un acte de générosité d'une personne vis à vis d'une autre
                        (ecrivain et lecteur)...
                        <br/><br/>
                        Je voudrais ajouter que l'important dans l'acte de créer ( qu'il s'agissent d'écrire un livre ou
                        peindre un tableau), c'est ce qui le motive... Et que faire preuve de générosit envers celui qui
                        sera à même de vous lire, comme de vous regarder, c'est lui donner sa juste place,
                        <br/><br/>
                        Reconnaitre le caractére décisif de la présence de l'autre dans tout ce que l'on fait.
                        <br/><br/>
                        Mais de quoi parle-t-on?...Du rôle de la générosité dans la création artistique.
                        <br/><br/>
                        Et de la gratitude aussi, par la même occasion. Celle que l'on a pour celui qui vous prête
                        attention.
                        <br/><br/>
                        Est-il bien pertinent d'évoquer tout cela!
                        <br/><br/>
                        Propos totalement absent de l'art contemporain aujourd'hui.)
                    </p>
                </div>
            </div>
        );
    }

}

export {VisiteursSoir};
