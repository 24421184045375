import React from "react";

class Trefle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}}>
                <div className="painting-item-left" id="dame-trefle">
                    <div className="painting-container">
                        <img src='/grostesque-2015/dame-trefle.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La Dame de Trèfle
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" id="anglaise">
                    <div className="painting-container">
                        <img src='/grostesque-2015/anglaise.webp' className="painting-image"></img>
                        <div className="painting-title">
                            L'Anglaise
                            <br/>
                            Mai 2015
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Trefle};
