import React from "react";
import {Galiano} from "./galiano";
import {Tang} from "./tang";
import {Bowie} from "./bowie";
import {Megere} from "./megere";
import {Heliodora} from "./heliodora";
import {Sphinx} from "./sphinx";
import {Sassolo} from "./sassolo";
import {Perouse} from "./perouse";
import {Jane} from "./jane";
import {Da} from "./da";
import {Tsang} from "./tsang";
import {Gargouille} from "./gargouille";
import {Piercing} from "./piercing";
import {Centaure} from "./centaure";
import {End} from "./end";
import {Basse} from "./basse";
import {Adele} from "./adele";
import {Herault} from "./herault";
import {Others} from "./others";
import {AlphabetIntro} from "./alphabet-intro";

class Alphabet2013 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <AlphabetIntro></AlphabetIntro>
            <hr/>
            <Galiano></Galiano>
            <hr/>
            <Tang></Tang>
            <hr/>
            <Bowie></Bowie>
            <hr/>
            <Megere></Megere>
            <hr/>
            <Heliodora></Heliodora>
            <hr/>
            <Sphinx></Sphinx>
            <hr/>
            <Sassolo></Sassolo>
            <hr/>
            <Perouse></Perouse>
            <hr/>
            <Jane></Jane>
            <hr/>
            <Da></Da>
            <hr/>
            <Tsang></Tsang>
            <hr/>
            <Gargouille></Gargouille>
            <hr/>
            <Piercing></Piercing>
            <hr/>
            <Centaure></Centaure>
            <hr/>
            <End></End>
            <hr/>
            <Basse></Basse>
            <hr/>
            <Adele></Adele>
            <hr/>
            <Herault></Herault>
            <hr/>
            <Others></Others>
        </div>)
    }

}

export {Alphabet2013};
