import React from "react";

class Triptique extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="triptique">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <div id="painting-dogana">
                            <img src='/fourteen-2013/triptique-1.webp' className="painting-image triptique-item">
                            </img>
                            <img src='/fourteen-2013/triptique-2.webp' className="painting-image triptique-item">
                            </img>
                            <img src='/fourteen-2013/triptique-3.webp' className="painting-image triptique-item">
                            </img>
                        </div>
                        <div className="painting-title">
                            Femme,Homme et Guerrier
                            <br/>
                            2012
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Triptique: Femme,Homme et Guerrier</h3>
                    <p className="painting-item-text">
                        Fin décembre 2012, je réalisais le tryptique : "femme,homme et guerrier" ( format 120 x 240) à
                        l'origine de cette série dont la totalité des pièces seront publiées ici, une à une, à la suite
                        de leur confection.
                        <br/><br/>
                        Il s'agit de portraits imaginaires, avec la même construction, respectant le même style.
                        <br/><br/>
                        L'ensemble sera de vingt-six, soit le nombre de lettres de l'alphabet ( ensuite je passerai à
                        autre chose, suivant cette règle que je me suis toujours fixée."
                    </p>
                </div>
            </div>
        );
    }

}

export {Triptique};
