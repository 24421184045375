import React from "react";

class End extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="end">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/end.webp' className="painting-image"></img>
                        <div className="painting-title">
                            La fin du monde
                            <br/>
                            Avril 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">La fin du monde</h3>
                    <p className="painting-item-text">
                        "Le titre de ce tableau m'est venu à la fin...
                        <br/>
                        C'est un vénitien, le marquis de mon dernier recueil de poésie, à l'époque du carnaval.
                        <br/>
                        Il me rappelle cette : "Ville où la saleté et la maladie ont été de tout temps, comme l'insulte
                        et le crachat, un fil de l'étoffe, le signe inquétant d'une bonne fortune, le pur joyau caché et
                        sans cesse découvert d'une étourdissante et inimitable fête"...
                        <br/>
                        (Sassolo ou le paysage vénitien...)
                        <br/><br/>Malpas
                        <br/>Février 1985
                    </p>
                </div>
            </div>
        );
    }

}

export {End};
