import React from "react";

class Sassolo extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="sassolo">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/sassolo.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Sassolo
                            <br/>
                            Février 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Sassolo</h3>
                    <p className="painting-item-text">
                        Sassolo est le personnage d'un roman d'Oscar V. de Loubicz Milosz : l'amoureuse initiation.
                        Texte poêtique, allégorique qui se déroule à Venise.
                        <br/>
                        La lecture sur scène des "Scènes de Don Juan" par Laurent Terzief, il y a une quarantaine
                        d'années, fut à l'origine de ma passion pour la poésie.
                    </p>
                </div>
            </div>
        );
    }

}

export {Sassolo};
