import React from "react";

class PetitsFormatsIntro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="formosande">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/fourteen-2013/formosande.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Formosande
                            <br/>
                            Juin 2014 (70 x x120)
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" style={{display: "block"}}>
                    <h3 className="painting-text-subtitle">Préface aux petits formats</h3>
                    <p className="painting-item-text">
                        J'ai lu derniérement une interview d'Haruki Murakami datant de juin 2003, publiée dans le
                        "Magazine Littéraire".
                        <br/><br/>
                        Il disait que nous vivions chacun dans notre monde, mais que d'autres mondes existaient juste à
                        coté, et qu'il ne revenait qu'à nous de franchir le pas et de sauter par dessus le mur pour
                        pénétrer un autre monde.
                        <br/><br/>
                        Et qu'en Asie, au Japon particuliérement, on considére que coéxistent deux mondes parralléles
                        bordés d'innombrables passerelles qui nous permettent de passer librement de l'un à l'autre.
                        <br/><br/>
                        J'ai rarement été aussi intéressé par le contenu d'un article.
                        <br/><br/>
                        En fait, j'y ai trouvé l'illustration de certaines choses qu'il y avait en moi, qui se
                        manifestent dans ma peinture mais dont je n'avais jamais encore trouvé d'explication aussi
                        claire.
                        <br/><br/>
                        Ma peinture est bourrée de liens, de passerelles, de passages.
                        <br/><br/>
                        La couleur, la ligne, la composition, l'encre du dessin et de la calligraphie, tout tend à une
                        seule chose: me permettre de passer de l'un à l'autre.
                        <br/><br/>
                        La vingtaine de petits formats que je présente ici bouillonnent de ces chemins de traverse.
                    </p>
                </div>
            </div>
        );
    }

}

export {PetitsFormatsIntro};
