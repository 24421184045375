import React from "react";
import {PetitsFormatsIntro} from "./petits-formats-intro";
import {Dogana} from "./dogana";
import {Biton} from "./biton";
import {Portraits} from "./portraits";
import {Visages} from "./visages";
import {Autres} from "./autres";
import {Eve} from "./eve";

class PetitsFormats2014 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <PetitsFormatsIntro></PetitsFormatsIntro>
                <hr/>
                <Dogana></Dogana>
                <hr/>
                <Biton></Biton>
                <hr/>
                <Portraits></Portraits>
                <hr/>
                <Visages></Visages>
                <hr/>
                <Autres></Autres>
                <Eve></Eve>
            </div>
        );
    }

}

export {PetitsFormats2014};
