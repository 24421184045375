import React from "react";

class Aden extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="aden">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/grands-formats-2015/aden.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Les femmes d'Aden
                            <br/>
                            (148 X 122) Juillet 2015
                        </div>
                    </div>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/grands-formats-2015/brasil.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Boipeba - Brésil
                            <br/>
                            Décembre 2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Epilogue</h3>
                    <p className="painting-item-text">
                        je ne fais pas de portraits.
                        <br/><br/>
                        Quel intérêt y a-t-il aujourd'hui à faire des portraits.
                        <br/><br/>
                        A la suite de qui !
                        <br/><br/>
                        Avec quelle intention ?
                        <br/><br/>
                        Tout a déjà été dit.
                        <br/><br/>
                        Un jour en 2011, sur un salon où j'exposais de l'abstrait, un galériste a commenté mon travail
                        en passant devant mon stand vide. "Je n'y crois pas. L'art contemporain est figuratif.".
                        Du coup, je me suis mis, à dessiner des bouches et des yeux dans mes toiles pour que celles-ci
                        soient plus compréhensibles.
                        <br/><br/>
                        C'est aussi simple que cela.
                        <br/><br/>
                        Je ne suis jamais soucié de savoir s'il avait tort ou raison.
                        <br/><br/>
                        Ca n'a pas la moindre importance.
                        <br/><br/>
                        L'essentiel est ailleurs.
                        <br/><br/>
                        Ce qui mérite l'attention c'est ce qui vient de lui et de lui seul.
                        <br/><br/>
                        Son originalité.
                        <br/><br/>
                        <i>Les Issambres, avril 2015</i>
                    </p>
                </div>
            </div>
        );
    }

}

export {Aden};
