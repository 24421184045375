import React from "react";

class Centaure extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="centaure">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/centaure.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Centaure
                            <br/>
                            Avril 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Centaure</h3>
                    <p className="painting-item-text">
                        J'ai pris conscience que je dessinais un centaure au moment où la coiffe a commençé à se
                        positionner sur son visage.
                    </p>
                </div>
            </div>
        );
    }

}

export {Centaure};
