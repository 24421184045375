import React from "react";

class Tsang extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="tsang">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/tsang.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Ts'ang KI
                            <br/>
                            Janvier 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Ts'ang KI</h3>
                    <p className="painting-item-text">
                        Ts'ang Ki, "le voyant". Celui que l'on considère comme l'inventeur de la calligraphie (2600 ans
                        avant J.C).
                        <br/>
                        Très souvent , il est représenté, les épaules couvertes d'une tunique de feuilles et possède
                        deux paires d'yeux. (don de double vue)
                        <br/>
                        C'est l'initiateur d'une écriture millénaire.
                        <br/>
                        Il s'inspire de la cosmologie chinoise, de la trace des pattes d'oiseaux sur le sol, et place
                        l'écriture au centre de ce principe qui régie le monde.
                        <br/>
                        En tant qu'artiste, j'ai toujours pensé produire des signes.
                        <br/>
                        Le besoin que j'ai de couvrir mes tableaux de signalétique tient à cette nécessité de
                        reconstituer un langage qui va parfois jusqu'à l'élaboration d'un véritable alphabet.
                        <br/>
                        A chaque lettre posée, j'ouvre un livre
                    </p>
                </div>
            </div>
        );
    }

}

export {Tsang};
