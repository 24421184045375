import React from "react";

class Gargouille extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="gargouille">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/gargouille.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Gargouille
                            <br/>
                            Mars 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Gargouille</h3>
                    <p className="painting-item-text">
                        Statuette emblématique du moyen-âge positionnée
                        <br/>à hauteur des donjons
                        <br/>et des chemins de ronde
                        <br/>qui fait office de gouttière
                        <br/>pour l'évacuation des eaux de pluie...
                    </p>
                </div>
            </div>
        );
    }

}

export {Gargouille};
