import React from "react";

class Visages extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="visages">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <div id="painting-dogana">
                            <img src='/petits-formats-2014/visage.webp' className="painting-image portrait-item">
                            </img>
                            <img src='/petits-formats-2014/visage2.webp' className="painting-image portrait-item">
                            </img>
                        </div>
                        <div className="painting-title">
                            Visages violacés
                            <br/>
                            Septembre 2014
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Visages violacés</h3>
                    <p className="painting-item-text">
                        "Ces nuits de Septembre, la couleur tendait au bleu violaçé...
                        <br/><br/>
                        A tel point que J'ai cru un moment qu'il s'agissait de deux soeurs."
                    </p>
                </div>
            </div>
        );
    }

}

export {Visages};
