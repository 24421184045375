import React from "react";

class Basse extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="basse">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/basse.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Basse de la Juive
                            <br/>
                            Juin 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Basse de la Juive</h3>
                    <p className="painting-item-text">
                        Fini le 9 juin 2013, le titre de ce tableau évoque un lieu historique de naufrage au Sud du
                        canal du Mazambique dans l'ocean Indien...
                        <br/>
                        Volcan immergé en plein ocean au large de Madagascard, dans les eaux térritoriales francaises,
                        il affleure dangereusement à la crête des vagues par marée basse. Invisible par mauvais
                        temps, il piège tout navigateur.
                        <br/>
                        Il est connu pour le nauvrage du Santiago, navire portugais, au XVIIeme siécle.
                        <br/>
                        Ce lieu évoque le danger maritime, invisible et permanent, les récifs, les nauvrages, les
                        épaves, comme des pieurs d'épaves, les chercheurs de trésor, et les matelots égarrés, piégés par
                        la tempête.
                        <br/>
                        Il fait parti de mon imaginaire, comme Zezura, l'oasis mythique du désert lybien sur lequel
                        écrivit Théodore Monod.
                    </p>
                </div>
            </div>
        );
    }

}

export {Basse};
