import {createSlice} from '@reduxjs/toolkit'

export const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        itemIterator: 1,
        spin: true,
        showMenu: false
    },
    reducers: {
        iterateItems: (state, action) => {
            state.itemIterator = action.payload;
        },
        spinning: (state, action) => {
            state.spin = action.payload;
        },
        tooglingMenu: (state, action) => {
            state.showMenu = action.payload;
        },
    },
})

export const {iterateItems, spinning, tooglingMenu} = sharedSlice.actions

export default sharedSlice.reducer
