import React from "react";

class Da extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="da">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/da.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Da
                            <br/>
                            Janvier 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Da</h3>
                    <p className="painting-item-text">
                        Da est une abréviation. Il s'agit d'un portrait imaginaire de Salvador Dali. La photo a été
                        prise juste avant que je ne dessine les moustaches.
                        <br/>
                        Donc j'ai décidé de l'appeler Da et non Dali, comme si il lui manquait une partie de ses
                        attributs.
                    </p>
                </div>
            </div>
        );
    }

}

export {Da};
