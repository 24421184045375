import React from "react";

class Eve extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="eve">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/petits-formats-2014/eve.webp' className="painting-image">
                        </img>
                        <div className="painting-title">
                            Eve
                            <br/>
                            (80 X 60)
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <div className="painting-container">
                        <img src='/petits-formats-2014/gardienne-jardins.webp' className="painting-image">
                        </img>
                        <div className="painting-title">
                            La gardienne des jardins
                            <br/>
                            (120 X 100)
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {Eve};
