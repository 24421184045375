import React from "react";

class LesDeuxVents extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="vents">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/vents.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Les deux vents
                            <br/>
                            120 x 160
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Les deux vents</h3>
                    <p className="painting-item-text">
                        Il y a deux vents... le vent de jour et le vent de nuit.
                        <br/><br/>
                        Homme et femme visages reliés par un faisceau de liens au rivage d'une ile.
                        <br/><br/>
                        J'ai ouvert la fenêtre. Le vent d'Est s'engouffre dans la piéce où je vis... Du dehors parvient
                        le bruisement de l'air dans le feuillage des bignones.
                        <br/><br/>
                        Vous me rappelez que nous vivons ici à l'abri du Mistral...Et que les vents provencaux se
                        dénombrent par dizaine.
                        <br/><br/>
                        Vous me dîtes que ce titre: "les deux Vents" ne s'impose pas... et que peindre le vent est un
                        acte superflu... car le Vent ne se représente pas.
                        <br/><br/>
                        Mais ce n'est pas peindre, c'est simplement la pensée du Vent qui m'intéresse.
                        <br/><br/>
                        Comme le Paradis..."
                    </p>
                </div>
            </div>
        );
    }

}

export {LesDeuxVents};
