import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';

const WebMenu = () => {
    const location = useLocation();
    let pathname = location.pathname;
    let path = pathname.split("#")[0].replaceAll("/", "");
    return (<div className="container" id="menu-web">
        <div className="menu-divider">
            <Link className={(pathname.indexOf('home') !== -1 || pathname === '/') ? 'menu-item-selected' : 'menu-item'}
                  to="/home">
                <FormattedMessage id="menu.home"/>
            </Link>
            <div className="menu-vertical-bar"></div>
            {/*<Link className={pathname.indexOf('portrait') !== -1 ? 'menu-item-selected' : 'menu-item'} to="/portrait">*/}
            {/*    <FormattedMessage id="menu.portrait"/>*/}
            {/*</Link>*/}
            <div className="menu-vertical-bar"></div>
            <Link className={pathname.indexOf('grands-formats-2015') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/grands-formats-2015">
                Grands formats 2015
            </Link>
            <div className="menu-vertical-bar"></div>
            <Link className={pathname.indexOf('grotesque-2015') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/grotesque-2015">
                Grotesque 2015
            </Link>
            <div className="menu-vertical-bar"></div>
            <Link className={pathname.indexOf('grands-formats-2014') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/grands-formats-2014">
                Grands formats 2014
            </Link>
            <div className="menu-vertical-bar"></div>
        </div>
        <div className="menu-divider">
            <Link className={pathname.indexOf('petits-formats-2014') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/petits-formats-2014">
                Petits formats 2014
            </Link>
            <div className="menu-vertical-bar"></div>
            <Link className={pathname.indexOf('quatorze-2013') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/quatorze-2013">
                Quatorze 2013
            </Link>
            <div className="menu-vertical-bar"></div>
            <Link className={pathname.indexOf('alphabet-2013') !== -1 ? 'menu-item-selected' : 'menu-item'}
                  to="/alphabet-2013">
                Alphabet 2013
            </Link>
            <div className="menu-vertical-bar"></div>
            <Link className='menu-item'
                  to={"/" + path + "#contact"}>
                Contact
            </Link>
        </div>
    </div>);
}

export {WebMenu};
