import React from "react";

class A extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}}>
                <div className="painting-item-left" id="a">
                    <div className="painting-container">
                        <img src='/grostesque-2015/a.webp' className="painting-image"></img>
                        <div className="painting-title">
                            A
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" id="financiere-news">
                    <div className="painting-container">
                        <img src='/grostesque-2015/financiere-news.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Financière news
                            <br/>
                            2015
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {A};
