import React from "react";
import {PortraitIntro} from "./portrait-intro";
import {Triptique} from "./triptique";
import {useLocation} from "react-router-dom";

export const Portrait = () => {
    const location = useLocation();
    let pathname = location.pathname;
    return (<div>
        <PortraitIntro></PortraitIntro>
        <hr/>
        <Triptique></Triptique>
    </div>)
}
