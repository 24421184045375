import React from "react";

class TroisMondes extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="trois-mondes">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/grands-formats-2015/trois-mondes.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Les Trois Mondes
                            <br/>
                            (158 X 122) Fevrier 2015
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Les Trois Mondes</h3>
                    <p className="painting-item-text">
                        Réne Daumal écrit dans l'un de ses livres: "Tout poête parle trop tôt ou trop tard".
                        <br/><br/>
                        (Il commentait alors les derniers mots d'un condamné à mort avant une exécution capitale.)
                        <br/><br/>
                        Cette phrase lue il y a une quarantaine d'année m'a marqué.
                        <br/><br/>
                        On peut interprêter de diverses maniéres la phrase de Daumal... Soit comme une absence de
                        rigueur de la part du poête qui ne parle pas précisement au moment où il faut...Ou alors y voir
                        l'expression d'une fatalité qui veut que celui-ci se tait (ou ne trouve pas ses mots) au moment
                        précis, essentiel, où il doit s'exprimer.
                        <br/><br/>
                        Ces deux avis montre la difficulté qu'il y a à maitriser son langage.
                        <br/><br/>
                        La parole n'a d'importance que lorsqu'elle colle à la réalité et ne se manifeste qu'à l'instant
                        précis où l'on a besoin d'elle.
                        <br/><br/>
                        Parler au bon moment....C'est un sujet de reflexion pour toute personne qui crée.
                        <br/><br/>
                        Trop tôt ou trop tard est le reflet de nos insufisances et de notre bavardage.
                        <br/><br/>
                        Parler trop aussi pollue notre discours.
                        <br/><br/>
                        Mais venons-en au fait!
                        <br/><br/>
                        Je peins souvent.
                        <br/>
                        "Cette parole perdue'" (pour reprendre un poême de Daumal) est sans doute la mienne, à moi
                        aussi.
                        <br/><br/>
                        Je doute cependant que percevoir ce moment essentiel soit l'essentiel de la vie.
                        <br/><br/>
                        Mais vous trouvrez dans le tableau "les Trois Mondes" deux, trois références à l'heure juste...
                        <br/><br/>
                        <i>Le 28 juin 2015</i>
                    </p>
                </div>
            </div>
        );
    }

}

export {TroisMondes};
