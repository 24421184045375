import React from "react";

class Tang extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="tang">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/tang.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Tang
                            <br/>
                            Janvier 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Tang</h3>
                    <p className="painting-item-text">
                        J'ai feuilleté attentivement un ouvrage publié en 2004 à l'initiative de la Bibliothéque
                        Nationale de France à l'occasion de l'exposition : "Chine, l'Empire du Trait".
                        <br/>
                        On y trouve en préface cette phrase du moine boudhiste, peintre et calligraphe, Shitao:
                        "l'Unique Trait de pinceau est l'origine de toutes choses, la racine de tous les phénomènes".
                        <br/>
                        J'ai toujours considéré que mon travail, à un moment donné, passait par l'écriture ou ce
                        que l'on peut appeller de la calligraphie.
                    </p>
                </div>
            </div>
        );
    }

}

export {Tang};
