import React from "react";

class Autres extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="autres">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <div id="painting-dogana">
                            <img src='/petits-formats-2014/autres-1.webp' className="painting-image dogana-item">
                            </img>
                            <img src='/petits-formats-2014/autres-2.webp' className="painting-image dogana-item">
                            </img>
                            <img src='/petits-formats-2014/autres-3.webp' className="painting-image dogana-item">
                            </img>
                        </div>
                        <div id="painting-dogana" style={{marginTop: 20}}>
                            <img src='/petits-formats-2014/autres-4.webp' className="painting-image dogana-item">
                            </img>
                            <img src='/petits-formats-2014/autres-5.webp' className="painting-image dogana-item">
                            </img>
                            <img src='/petits-formats-2014/autres-6.webp' className="painting-image dogana-item">
                            </img>
                        </div>
                        <div className="painting-title">
                            Autres petits formats
                            <br/>
                            2014
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Autres petits formats</h3>
                    <p className="painting-item-text">
                        "Pourquoi ajouter des mots qui ont trainé partout à ces choses fraiches qui s'en passaient si
                        bien?"
                        <br/><br/>
                        <i>Nicolas Bouvier (1929-96)</i>
                    </p>
                </div>
            </div>
        );
    }

}

export {Autres};
