import React from "react";

class EspritsComedie extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" style={{alignItems: 'flex-end'}} id="inspiration">
                <div className="painting-item-left" style={{textAlign: "right"}}>
                    <div className="painting-container">
                        <img src='/grands-formats-2014/inspiration.webp' className="painting-image"></img>
                        <div className="painting-title">
                            L'inspiration
                            <br/>
                            Avril 2015 (100 x 160)
                        </div>
                    </div>
                </div>
                <div className="painting-item-right" id="comedie">
                    <div className="painting-container">
                        <img src='/grands-formats-2014/comedie.webp' className="painting-image"></img>
                        <div className="painting-title">
                            L'esprit de la comédie
                            <br/>
                            160 x 100
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export {EspritsComedie};
