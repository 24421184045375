import React from "react";

class Herault extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="herault">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/herault.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Herault de Séchelles
                            <br/>
                            Juin 1013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Herault de Séchelles</h3>
                    <p className="painting-item-text">
                        L'ami d'Adéle de Bellegarde.
                        <br/>
                        Guillotiné lui, durant la terreur, sous la Revolution.
                        <br/>
                        Ces contemporains soulignent tous son exceptionnelle beauté.
                        <br/>
                        Jerome Garcin a écrit un livre romanesque sur lui. "C'était tous les jours tempête".
                        <br/>
                        Il y a quarante ans, si j'avais eu l'oportunité d'effectuer une étude, une recherche, sur un
                        personnage romantique historique, c'est lui que j'aurais choisi.
                    </p>
                </div>
            </div>
        );
    }

}

export {Herault};
