import React from "react";

class Biton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="biton">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/petits-formats-2014/biton.webp' className="painting-image">
                        </img>
                        <div className="painting-title">
                            Biton
                            <br/>
                            Juin 2014
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Biton</h3>
                </div>
            </div>
        );
    }

}

export {Biton};
