import React from "react";

class AlphabetIntro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/fourteen-icon.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Sixte de Mijolla
                            <br/>
                            L'atelier des Issambres
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">L'alphabet</h3>
                    <p className="painting-item-text">
                        Les compositions de Janvier à Juin. Un alphabet, une serie de 26.
                    </p>
                    <div style={{marginTop: 20}} className="painting-container">
                        <img src='/alphabet-2013/signature.webp' className="painting-image"
                             style={{maxWidth: 200}}></img>
                    </div>
                </div>
            </div>
        );
    }

}

export {AlphabetIntro};
