import React from "react";
import {QuatorzeIntro} from "./quatorze-intro";
import {Others} from "./others";

class Quatorze2013 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <QuatorzeIntro></QuatorzeIntro>
            <hr/>
            <Others></Others>
        </div>)
    }

}

export {Quatorze2013};
