import React from "react";
import {Aden} from "./aden";
import {Jardins} from "./jardins";
import {TroisMondes} from "./trois-mondes";

class GrandsFormats2015 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <Jardins></Jardins>
            <hr/>
            <TroisMondes></TroisMondes>
            <hr/>
            <Aden></Aden>
        </div>)
    }

}

export {GrandsFormats2015};
