import React from "react";

class Bowie extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="painting-item" id="bowie">
                <div className="painting-item-left">
                    <div className="painting-container">
                        <img src='/alphabet-2013/bowie.webp' className="painting-image"></img>
                        <div className="painting-title">
                            Bowie
                            <br/>
                            Février 2013
                        </div>
                    </div>
                </div>
                <div className="painting-item-right">
                    <h3 className="painting-text-subtitle">Bowie</h3>
                    <p className="painting-item-text">
                        Il y a une quarantaine d'années, j'ai croisé dans les rues de Zurich, sous la pluie, un matin de
                        carnaval, un personnage qui ressemblait étrangement à David Bowie.
                        <br/>
                        Cette image m'est restée.
                        <br/>
                        Ce tableau est une façon de célébrer le nouveau disque du chanteur, par un portrait
                        totalement imaginaire.
                    </p>
                </div>
            </div>
        );
    }

}

export {Bowie};
