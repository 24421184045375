import React, {useEffect} from "react";
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import {Articles} from "./articles";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {iterateItems, spinning} from "../../state/shared"
import {PortraitIntro} from "../portrait/portrait-intro";

export const Home = () => {
    let iterator = useSelector((state) => state.shared.itemIterator);
    let spin = useSelector((state) => state.shared.spin);
    const maxIterator = 4;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mijolla = "mijolla";
    useEffect(() => {
        setTimeout(() => spinItems(spin), 5000);
    });
    const spinItems = (spinning) => {
        if (spinning) {
            dispatch(iterateItems(iterator === maxIterator ? 1 : iterator + 1));
        }
    }
    const switchTo = (itemNumber) => {
        dispatch(spinning(false));
        dispatch(iterateItems(itemNumber));
    };
    const goTo = (portrait, anchor) => {
        dispatch(spinning(false));
        if (!!anchor && anchor.length > 0) {
            navigate(`/${portrait}#${anchor}`, {replace: true});
        } else {
            navigate(`/${portrait}`, {replace: true});
        }
    };

    function goToVid() {
        window.location.href = "/" + mijolla + ".mp4";
    }

    return (
        <div>
            <div id="on-motion-container">
                <div id="on-motion-sub-container">
                    <div className="on-motion-background"
                         onClick={() => goToVid()}>
                        <video autoPlay muted loop id="sixte-video">
                            <source src="/clip.mp4" type="video/mp4"></source>
                        </video>
                    </div>
                </div>
            </div>
            <div id="on-motion">
                <FormattedMessage id="home.on-video"></FormattedMessage>
            </div>
            <PortraitIntro></PortraitIntro>
            <div id="on-motion">
                <FormattedMessage id="home.on-motion"></FormattedMessage>
            </div>
            <Articles></Articles>
        </div>
    );
}
