import {Article} from "./article";

export const Articles = () => {
    return (<div id="articles">
        <div className="article-line-main">
            <div className="article-main">
                <Article backgroundImage="url(/grands-formats-2015/trois-mondes.webp)"
                         articleTitle="Collection 2015 - Les trois mondes" clazz="left-article"
                         linkTo="/grands-formats-2015#trois-mondes">
                </Article>
            </div>
            <div className="article-square">
                <div className="article-square-line">
                    <Article backgroundImage="url(/grands-formats-2015/brasil.webp)" clazz="main-left-article"
                             articleTitle="Epilogue à la collection 2015" linkTo="/grands-formats-2015#aden">
                    </Article>
                    <Article backgroundImage="url(/fourteen-2013/formosande.webp)"
                             articleTitle="Inspiration petits formats"
                             linkTo="/petits-formats-2014"
                             clazz="right-article">
                    </Article>
                </div>
                <div className="article-square-line">
                    <Article backgroundImage="url(/alphabet-2013/atelier-galiano.webp)"
                             clazz="bottom-article main-left-article galiano"
                             linkTo="/alphabet-2013#galiano"
                             articleTitle="Potrait de John Galiano">
                    </Article>
                    <Article backgroundImage="url(/grostesque-2015/grotesque.webp)"
                             clazz="right-article bottom-article"
                             linkTo="/grotesque-2015"
                             articleTitle="Collection 2015: 'Grotesque'">
                    </Article>
                </div>
            </div>
        </div>
        <div className="article-line">
            <div className="article-subline">
                <Article backgroundImage="url(/alphabet-2013/sphinx.webp)" clazz="left-article"
                         linkTo="/alphabet-2013#sphinx"
                         articleTitle="2013 - SPHINX NONZA">
                </Article>
                <Article backgroundImage="url(/grands-formats-2014/arbre.webp)"
                         linkTo="/grands-formats-2014#arbre"
                         articleTitle="2014 - ARBRE GÉNÉALOGIQUE">
                </Article>
            </div>
            <div className="article-subline">
                <Article backgroundImage="url(/grands-formats-2015/jardins.webp)"
                         linkTo="/grands-formats-2015#jardins" clazz="main-left-article"
                         articleTitle="2015 - Jardin d'acclimation">
                </Article>
                <Article backgroundImage="url(/grands-formats-2014/visiteurs.webp)"
                         linkTo="/grands-formats-2014#visiteurs"
                         articleTitle="2014 - Visiteurs du soir">
                </Article>
            </div>
        </div>
    </div>);
}
