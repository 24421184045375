import React from "react";
import {GrotesqueIntro2015} from "./grotesque-intro-2015";
import {Rappaccini} from "./rappaccini";
import {Katmandou} from "./katmandou";
import {A} from "./a";
import {Trefle} from "./trefle";
import {EauProfonde} from "./eau-profonde";

class Grotesque2015 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
                <GrotesqueIntro2015></GrotesqueIntro2015>
                <Rappaccini></Rappaccini>
                <Katmandou></Katmandou>
                <A></A>
                <Trefle></Trefle>
                <EauProfonde></EauProfonde>
            </div>
        )
    }

}

export {Grotesque2015};
