import React from "react";
import {GrandsFormatsIntro2014} from "./grands-formats-intro-2014";
import {LesDeuxAmies} from "./les-deux-amies";
import {Arbre} from "./arbre";
import {LesDeuxVents} from "./les-deux-vents";
import {EspritsComedie} from "./esprits-comedie";
import {VisiteursSoir} from "./visiteurs-soir";

class GrandsFormats2014 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <GrandsFormatsIntro2014></GrandsFormatsIntro2014>
            <hr/>
            <LesDeuxAmies></LesDeuxAmies>
            <hr/>
            <Arbre></Arbre>
            <hr/>
            <LesDeuxVents></LesDeuxVents>
            <EspritsComedie></EspritsComedie>
            <VisiteursSoir></VisiteursSoir>
        </div>)
    }

}

export {GrandsFormats2014};
